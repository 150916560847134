@import "src/style/config/vars";
@import "src/style/helpers/mixins";

.login {
  position: relative;
  height: 100vh;
  @include media-breakpoint-up($xl) {
    display: flex;
  }
}
.login__content {
  width: 60%;
  background-color: $primary;
  border-radius: 0 15px 15px 0;
  padding: 0% 8% 4%;
  @include media-breakpoint-down($xl) {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 90%;
    height: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }
}
.login__image {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 60px;
  min-width: 80%;
  height: 100%;
  transform: translateY(80px);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("./splash-image.png");
  @include media-breakpoint-down($xl) {
    padding-bottom: 5px;
    transform: translateY(20px);
    background-position: top;
  }
}
.login__copyright {
  font-style: italic;
  font-weight: $font-bold;
  font-size: 32px;
  color: $white;
  @include media-breakpoint-down($xl) {
    font-size: 24px;
    padding-bottom: 10px;
  }
}
.login__text-logo {
  font-style: italic;
  font-weight: $font-bold;
  font-size: 18px;
  color: $white;
  opacity: 0.7;
  @include media-breakpoint-down($xl) {
    font-size: 14px;
  }
}
.login__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  @include media-breakpoint-up($xl) {
    max-width: 770px;
    padding: 20px;
    width: 40%;
  }
  @include media-breakpoint-down($xl) {
    width: calc(90% - 20px);
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-35%);
  }
}
.login__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  padding: 18px 44px 38px;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 6px 12px -1px rgba($black, 0.2);
  @include media-breakpoint-down($xl) {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 14px 44px 41px;
    box-shadow: 0 4px 12px 1px rgba($black, 0.2);
  }
}
.login__logo {
  padding: 10px;
  width: 133px;
  margin-bottom: 25px;
  margin-right: 10px;
  @include media-breakpoint-down($xl) {
    margin-bottom: 24px;
    width: 115px;
    margin-left: 10px;
  }
}
.login__heading {
  margin-bottom: 54px;
  font-weight: $font-regular;
  font-size: 24px;
  text-align: center;
  color: $gray-400;
  @include media-breakpoint-down($xl) {
    margin-bottom: 42px;
    font-size: 20px;
    text-align: center;
  }
}
.login__app-name {
  color: $primary;
  font-weight: $font-black;
  font-size: 28px;
  @include media-breakpoint-down($xl) {
    font-size: 22px;
    font-weight: $font-bold;
  }
}
.login__login-button {
  display: flex;
  padding: 2px;
  background: $primary;
  align-items: center;
  border-radius: 3px;
  max-width: 233px;
  margin-bottom: 86px;
  border: none;
  @include media-breakpoint-down($xl) {
    margin-bottom: 62px;
  }
}
.login__text {
  margin-bottom: 6px;
  text-align: center;
  color: $gray-400;
  @include media-breakpoint-down($xl) {
    font-size: 14px;
    margin-bottom: 13px;
  }
}
.login__description {
  font-style: italic;
  font-size: 14px;
  line-height: 16px;
  margin-top: 15px;
  text-align: center;
  color: rgba($gray-400, 0.8);
  @include media-breakpoint-down($xl) {
    font-size: 12px;
    line-height: 14px;
  }
}
