@import "../../../../../style/config/vars";
@import "../../../../../style/helpers/mixins";

.dropdown__backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
}

.dropdown {
  position: relative;
  width: 100%;
  border-radius: 5px;
}

.dropdown__container {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 13px 5px;
  background-color: $white;
  border-radius: 5px;
  box-shadow: $primary-shadow;
  animation: showDropdown 0.3s ease-in-out 1;
  overflow: hidden;
  max-height: 320px;
  z-index: $z-index-backdrop;

  input {
    border-bottom: 1px solid $gray-100;
    margin-bottom: 5px;
    padding: 5px 10px;
    box-shadow: unset;
  }

  @include media-breakpoint-down($xl) {
    min-width: 100%;
    width: fit-content;
  }
}

@keyframes showDropdown {
  0% {
    opacity: 0;
    max-height: 70px;
    padding: 0 5px;
  }

  10% {
    opacity: 0;
  }

  55% {
    opacity: 1;
  }

  100% {
    max-height: 300px;
    padding: 13px 5px;
  }
}

.dropdown__list {
  max-height: 220px;
  overflow-y: auto;
}

.dropdown_label_wrap {
  display: inline-block;
  width: 80%;
}

.deselect__icon {
  background: url("../../../../../assets/close.svg");
  display: inline-block;
  position: absolute;
  right: 40px;
  top: 18px;
  height: 10px;
  width: 10px;
  color: $gray-300;
  cursor: pointer;

  &.hide {
    display: none;
  }
}

.search__input {
  display: inline-block;
  width: 100%;
  margin: 10px 0px;
  padding: 10px 10px;
  color: $gray-500;
  border: 1px solid $black;
  border-radius: 5px;
  font-weight: $font-medium;

  &.selected {
    display: none;
  }
}

.search__input::-webkit-search-cancel-button {
  width: 11px;
  height: 11px;
  opacity: 0.3;
  cursor: pointer;
}

.search__input::placeholder {
  color: $gray-300;
}

.dropdown__error {
  border: 1px solid $red;
}

.dropdown__error-message {
  color: $red;
  text-align: left;
  display: block;
  margin-bottom: 10px;
}

.dropdown_sort_button {
  margin-left: 58%;
}
