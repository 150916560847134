@import "src/style/config/vars";
@import "src/style/helpers/mixins";

.amortization-type-form {
  display: flex;
  flex-direction: column;
}
.amortization-type-form__title {
  font-weight: $font-bold;
  font-size: 20px;
  text-align: center;
  color: $gray-500;
  margin-bottom: 52px;
}

.amortization-type-form__controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 62px;
}
