.confirm-ownership-form {
  padding: 30px;
}

.confirm-ownership__text {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  padding: 0 30px;
  text-align: center;
}

.confirm-ownership__btn-wrap {
  width: 50%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
