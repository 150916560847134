.census-progress {
  .completed {
    color: #cde966;
  }

  .incomplete {
    color: #b99fea;
  }

  .pending {
    color: #95110a;
  }

  padding: 20px;
  background: linear-gradient(135deg, #f9f9f9, #eef1f5);
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition:
    box-shadow 0.3s ease,
    transform 0.3s ease;
  margin-bottom: 20px;

  &:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    transform: translateY(-3px);
  }

  .progress-header {
    font-weight: 600;
    margin-bottom: 10px;
    color: #2c3e50;
  }

  .detailed-progress {
    margin-top: 20px;

    max-height: 300px;
    overflow: auto;

    h3 {
      font-size: 1.2em;
      margin-bottom: 15px;
      color: #34495e;
    }

    .progress-item {
      margin-top: 10px;

      .category-name {
        margin-bottom: 5px;
        font-weight: 500;
        color: #7f8c8d;
      }
    }
  }
  .detailed-progress-check {
    padding-top: 10px;
  }

  h3 {
    position: sticky;
  }
}
