@import "src/style/config/vars";
@import "src/style/helpers/mixins";

.census-details-item-table {
  max-width: 100%;
  justify-content: center;
}

.details-table-header {
  display: block;

  @include media-breakpoint-up($sm) {
    --grid-size: 10;
    align-items: center;
    border: none;
    display: grid;
    gap: 0;
    grid-auto-flow: row;
    grid-template-columns: minmax(15%, 15%) minmax(15%, 15%) minmax(15%, 15%) minmax(15%, 15%) minmax(15%, 15%) minmax(
        15%,
        15%
      );
    justify-content: center;
  }
}

.census-details-table__grid {
  display: block;

  @include media-breakpoint-up($sm) {
    --grid-size: 10;
    align-items: center;
    border: none;
    display: grid;
    gap: 0;
    grid-auto-flow: row;
    grid-template-columns: minmax(15%, 15%) minmax(15%, 15%) minmax(15%, 15%) minmax(15%, 15%) minmax(15%, 15%) minmax(
        15%,
        15%
      );
    justify-content: center;
  }
}

.filters-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-options {
  margin-top: 20%;
}

.census-header-wrapper {
  width: 100%;
}

.filters-container {
  display: flex;
  margin-bottom: 20px;

  .filters-wrap {
    margin-left: auto;
    align-items: center;

    .text-input {
      margin-bottom: 0;
    }
  }
}

.census-options {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;
  background: linear-gradient(135deg, #f9f9f9, #eef1f5);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;

  .close-button {
    margin-left: auto;
  }
}
