@import "src/style/config/vars";
@import "src/style/helpers/mixins";

.census-item-table {
  max-width: 100%;
  justify-content: center;
}

.table-header {
  display: block;

  @include media-breakpoint-up($sm) {
    --grid-size: 10;
    align-items: center;
    border: none;
    display: grid;
    gap: 0;
    grid-auto-flow: row;
    grid-template-columns: minmax(30%, 30%) minmax(30%, 30%) minmax(30%, 30%) minmax(30%, 30%);
    justify-content: center;
  }
}

.census-items-table__grid {
  display: block;

  @include media-breakpoint-up($sm) {
    --grid-size: 10;
    align-items: center;
    border: none;
    display: grid;
    gap: 0;
    grid-auto-flow: row;
    grid-template-columns: minmax(30%, 30%) minmax(30%, 30%) minmax(30%, 30%) minmax(30%, 30%);
    justify-content: center;
  }
}

.highlighted {
  border-left: 5px solid #4c36c8;
  transition: background-color 0.3s ease;
}

.items-pagination {
  max-width: 100%;
}

.no-censuses {
  margin-top: 20px;
}
