@import "../../../style/config/vars";

.error-message {
  color: $red;
}

.item-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
