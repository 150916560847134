@import "src/style/config/vars";
@import "src/style/helpers/mixins";

.stickers__content {
  background-color: $white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 80%;
  max-width: 450px;
  padding: 50px 20px 0;

  @include media-breakpoint-up($md) {
    width: 50%;
  }
}

.stickers__close {
  display: flex;
  justify-content: flex-end;
  .btn {
    padding: 0;
    box-shadow: none;
    border: 0;
    transition: transform 0.3s ease-in;
    @include hover {
      transform: scale(1.2);
    }
  }
}

.stickers__delete-icon {
  width: 17px;
}

.stickers__list {
  overflow-y: auto;
  height: 60vh;
  width: 250px;
  padding: 15px 0 10px 0;
  margin: 50px auto 0 auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $gray-300;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: $primary;
    border-radius: 10px;
  }
  .sticker {
    margin: 0 auto 10px;
  }
}

.stickers__print {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  .btn {
    min-width: 250px;
    justify-content: center;
  }
}

.stickers__print-location {
  margin-bottom: 15px;
  position: relative;
}

@media print {
  .stickers__list {
    overflow-y: visible;
    height: auto;
    width: 100%;
    padding: 0;
    margin: 0;
  }
}
