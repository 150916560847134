.progress-bar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background: linear-gradient(90deg, #e6e6e6, #f7f7f7);
  border: 1px solid #ccc;
  height: 25px;
  border-radius: 12px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  .progress {
    height: 100%;
    background: linear-gradient(90deg, #b99fea, #cde966);
    border-radius: 12px 0 0 12px;
    transition: width 0.5s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .progress-info {
    font-weight: bold;
    font-size: 14px;
    color: #333;
    display: flex;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    &.white-text {
      color: white;
    }
  }
}
