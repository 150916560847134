@import "src/style/config/vars";

.heading {
  font-weight: $font-bold;
  font-size: 20px;
  color: $gray-500;
  margin-bottom: 5px;
}
.description,
.warning {
  font-weight: $font-bold;
  font-size: 14px;
  line-height: 16px;
  color: $gray-400;
  opacity: 0.9;
  margin-bottom: 29px;
}
.warning {
  margin-top: 16px;
}
