@import "src/style/config/vars";
@import "src/style/helpers/mixins";

.sorting {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1350px;
  color: $gray-400;
  font-weight: $font-medium;
  font-size: 14px;
  margin: 0;
  flex-wrap: wrap;
  .btn {
    padding-left: 14px;
    padding-right: 14px;
  }
}

.sorting__text {
  margin-right: 22px;
  margin-bottom: 5px;
}

.sorting__field {
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;

  @include media-breakpoint-up($xl) {
    max-width: 250px;
  }
}

.sorting__dropdown {
  width: 175px;
  height: 50px;
}

.flex {
  display: flex;
  align-items: stretch;
  gap: 10px;
}
