@import "src/style/config/vars";
@import "src/style/helpers/mixins";

.inventory-config__content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.inventory-config__item {
  position: relative;
  margin-bottom: 30px;
  width: 100%;
  .sticker {
    margin: 0 auto;
  }
  &:not(.inventory-config__item--current) {
    .sticker {
      cursor: pointer;
      position: relative;
      transition: transform 0.3s ease;
      @include hover {
        transform: scale(1.15);
        z-index: 1;
      }
    }
  }
}

.inventory-config__item--current {
  .sticker {
    border: 4px solid $orange;
  }
}

.inventory-config__item--m-bottom {
  margin-bottom: 40px;
}

.census-badge {
  position: absolute;
  bottom: 100%;
  width: 75px;
  right: 0;
  height: 15px;
  border-radius: 8px;
  padding-left: 10px;

  &.incomplete {
    background-color: #b99fea;
    &:after {
      content: "Nepopisano";
      font-size: 10px;
      top: 0;
      position: absolute;
      color: white;
    }
  }

  &.completed {
    background-color: #cde966;
    &:after {
      content: "Potvrđeno";
      font-size: 10px;
      top: 0;
      position: absolute;
      color: #005344;
    }
  }

  &.pending {
    background-color: #95110a;
    &:after {
      content: "Za proveru";
      font-size: 10px;
      top: 0;
      position: absolute;
      color: white;
    }
  }
}
