@import "src/style/config/vars";

.preview-changes-small-inventory-value {
  & .squeeze-box {
    margin-top: 8px;
    box-shadow: 0px 0px 15px 0px rgba($color: #000000, $alpha: 0.15);
  }

  & .squeeze-box__header {
    font-size: 14px;
    font-weight: $font-bold;
    color: $gray-500;
    background: rgb(255, 255, 255);
    padding: 12px 16px;
  }

  &__total-changes {
    font-size: 13px;
    color: $gray-500;
    font-weight: 500;

    & span {
      font-weight: bold;
    }
  }

  &__body {
    padding: 8px 12px;
    background-color: rgb(251, 251, 251);
    max-height: 155px;
    overflow-y: auto;

    & > table {
      width: 100%;

      & td {
        padding: 16px 8px;
        vertical-align: middle;
      }
    }
  }

  & .small-inventory-value-settings__item-id {
    max-width: 95px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .small-inventory-value-settings__item-value {
    white-space: nowrap;

    color: $gray-500;
    font-weight: 500;
    font-size: 13px;
    &:not(:last-child) {
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.08);
    }
  }

  & .small-inventory-value-settings__item-value-label {
    font-size: 9px !important;
  }
}
