@import "src/style/config/vars";
@import "src/style/helpers/mixins";

.filters {
  width: 100%;
  max-width: 1350px;
  color: $gray-400;
  font-weight: $font-medium;
  font-size: 14px;
  margin: 0 10px 0 0;
  position: relative;
  justify-content: center;

  @include media-breakpoint-up($md) {
    margin-bottom: 20px;
  }

  @include media-breakpoint-up($xl) {
    justify-content: flex-start;
  }
}

.filters__btn {
  font-size: 30px;
  border: 0;
  background-color: unset;
  display: flex;
  align-items: center;
  position: relative;
}

.filters__btn--active {
  &::after {
    content: "";
    position: absolute;
    top: calc(100% - 9px);
    right: 3px;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 20px solid $white;
    filter: drop-shadow(-1px -5px 3px rgba(0, 0, 0, 0.1));
    z-index: 101;
  }
}

.filters__search {
  display: flex;

  @include media-breakpoint-up($md) {
    margin-bottom: 15px;
  }
}

.filters__container {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  width: 100%;
  z-index: 100;
  background-color: $white;
  max-height: 0;
  overflow: hidden;
  transition-property: max-height, box-shadow;
  transition: 0.5s ease;
  border-radius: 10px;
  box-shadow: 1px 12px 12px 6px rgba($black, 0);
  display: flex;
  align-items: center;
}

.filters__container--active {
  box-shadow: 1px 12px 12px 6px rgba($black, 0.25);
  max-height: 1000px;
  overflow: visible;

  .filters__wrap {
    opacity: 1;
    transition: $transition 0.2s;
  }
}

.filters__wrap {
  display: block;
  opacity: 0;
  padding: 40px 20px 20px;
  position: relative;
  transition: $transition;
  width: 100%;

  @include media-breakpoint-up($md) {
    padding: 30px 45px 30px 30px;
  }
}

.filters__close {
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 10px;

  .btn {
    padding: 3px;
  }

  @include media-breakpoint-up($md) {
    right: 20px;
    top: 20px;
  }
}

.filters__bottom {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.filters__options {
  display: flex;
  flex-wrap: wrap;
}

.filters__field {
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;

  @include media-breakpoint-up($xl) {
    margin-right: 20px;
    max-width: 250px;
  }
}

.filters__name {
  margin-right: 22px;
  margin-bottom: 5px;
}

.filters__dropdown {
  width: 175px;
  height: 50px;
  margin-left: 15px;
}

.filters__sort-wrap {
  width: 100%;

  @include media-breakpoint-up($xl) {
    width: auto;
  }
}

.filters__ctas {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-end;

  @include media-breakpoint-up($lg) {
    justify-content: space-between;
  }
}

.filters__additional {
  .flex {
    align-items: center;
    margin-bottom: 20px;
  }
  .btn {
    box-shadow: none;
    svg {
      margin-left: 5px;
    }
  }
}
