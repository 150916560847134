@import "src/style/config/vars";
@import "src/style/helpers/mixins";

.list-info__title {
  font-weight: $font-bold;
  font-size: 14px;
  color: $gray-500;
  opacity: 0.9;
  margin-bottom: 8px;
}
.list-info__list {
  padding: 16px 22px 25px 24px;
  background: $white;
  box-shadow: $gray-shadow;
  border-radius: 5px;
  max-width: 341px;
  margin-bottom: 30px;
  overflow-y: auto;
}
.list-info__list-item {
  display: flex;
  justify-content: space-between;
  font-weight: $font-regular;
  font-size: 14px;
  color: $gray-500;
  padding-bottom: 11px;
  border-bottom: 1px solid rgba($gray-400, 0.15);
  gap: 10px;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  .btn {
    width: 16px;
    height: 16px;
    border: none;
    padding: 0;
    box-shadow: none;
    transition: transform 0.3s ease-in;
    justify-content: flex-end;
    @include hover {
      transform: scale(1.2);
    }
  }
}
.list-info__vector {
  width: 13px;
  height: 13px;
  margin-left: 48%;
  cursor: pointer;
}
.list-info__delete-icon {
  width: 12px;
}
