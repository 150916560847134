@import "src/style/config/vars";

.create-new-item__controls {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  .btn {
    width: calc(50% - 12px);
    flex: 0 0 calc(50% - 12px);
    min-width: max-content;
    justify-content: center;
  }
}
.create-new-item__title {
  font-weight: $font-bold;
  font-size: 20px;
  text-align: center;
  color: $gray-500;
  margin-bottom: 52px;
}
.create-new-item__section {
  margin-bottom: 20px;
  font-size: 12px;
  color: rgba($gray-500, 0.5);
  min-height: 350px;
  &.valid {
    cursor: pointer;
    .accordion__header {
      border: 1px solid $green;
      box-shadow: $primary-shadow;
      transition: background-color $transition;
      &:hover {
        background-color: $green;
        .summary__title,
        .summary__subtitle {
          color: $white;
        }
      }
    }
    .summary__title,
    .summary__subtitle {
      color: $green;
      transition: color $transition;
    }
  }
  input {
    font-size: 14px;
  }
  .summary__title {
    margin-bottom: 5px;
  }
}
.create-new-item__navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0 24px;
}
.navigation-dot {
  width: 6px;
  height: 6px;
  background-color: rgba($gray-400, 0.4);
  border-radius: 50%;
  margin: 0 3px;
  &.highlighted {
    background-color: $green;
  }
}
