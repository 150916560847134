@import "../../../style/config/vars";

.toast {
  display: flex;
  align-items: center;
}
.toast-icon {
  width: 24px;
  margin-right: 12px;
}
.toast-message {
  font-weight: $font-bold;
  font-size: 14px;
  color: $gray-400;
  opacity: 0.8;
}
