.vertical {
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  max-width: 100%;
}

@media print {
  .vertical {
    display: block;
  }
}
