@import "src/style/config/vars";
@import "src/style/helpers/mixins";

.scan {
  width: 100%;
}

.scan__header {
  padding: 10px 7px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white;
  box-shadow: 3px 0px 20px -2px rgba($black, 0.15);
  margin-bottom: 10px;
}

.scan__logo {
  @include media-breakpoint-up($md) {
    flex: 0 0 10%;
    max-width: 10%;
  }
  @include media-breakpoint-down($md) {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

.scan__parts-header {
  margin-bottom: 10px;
}

.scan__title {
  font-size: 21px;
  line-height: 24px;
}

.scan__wrap {
  @include media-breakpoint-up($md) {
    max-width: 450px;
    margin: 0 auto;
  }
}

.scan__item-info {
  flex: 0 0 66.66%;
  max-width: 50%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.scan__main {
  display: flex;
  flex-direction: column;
}

.scan__item,
.scan__parts {
  flex: 0 0 50%;
  height: 50%;
}

.scan__confirmed {
  padding: 7px 0;
  border-bottom: 1px solid rgba($gray-400, 0.2);
}

.scan__confirmed-title {
  font-weight: $font-regular;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
}

.scan__confirmed-title--bold {
  font-weight: $font-bold;
  text-decoration: underline;
}

.scan__item {
  padding: 5px 10px;
}

.scan__content {
  margin-bottom: 10px;
}

.scan__item-wrap {
  &:not(.scan__item-wrap--single) {
    display: flex;
  }
}

.scan__label,
.scan__data {
  flex: 0 0 50%;
}

.scan__label {
  font-weight: $font-bold;
  color: $gray-400;
}

.scan__data {
  font-weight: $font-medium;
  color: $gray-500;
  word-break: break-all;
  background-color: $white;
  padding: 5px;
  min-height: 30px;
  display: flex;
  align-items: center;
}

.scan__btns {
  display: flex;
  gap: 40px;
  justify-content: center;
  padding: 15px 0;
  .scan__btn {
    justify-content: center;
    font-size: 18px;
    font-weight: $font-bold;
  }
}

.scan__error {
  text-align: center;
  font-size: 30px;
  height: 100%;
  display: flex;
  align-items: center;
}
