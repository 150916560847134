@import "src/style/config/vars";

.configuration-changes__changed_state {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 50px;
}

.line {
  width: 100%;
}

.btn__arrow-icon {
  color: $gray-400;
}
