@import "../../../../../style/config/vars";
@import "../../../../../style/helpers/mixins";

.invoice_div {
  margin-bottom: 1.8rem;

  .text-input__label {
    font-size: 13px;
  }

  .date-picker__label {
    font-size: 13px;
  }

  .tab__content {
    padding: 20px 25px;
  }

  .dropdown__container {
    position: relative;
  }

  .dropdown {
    margin-bottom: 30px;
  }

  .dropdown__container {
    max-height: fit-content;
  }

  .btn {
    width: 9rem;
    height: 1.9rem;
    padding: 0.1rem;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }

  .btn__text {
    font-size: 12px;
  }
}

.supplier_div {
  display: flex;
  margin-bottom: 1rem;
  width: 100%;

  .dropdown__container {
    position: relative;
  }

  .tabs {
    width: 100%;
  }

  .tabs__button-wrap {
    width: 100%;
  }

  .tab__button {
    padding: 10px 30px;
  }
}

.choose_supplier_div {
  width: 100%;
  position: relative;
}

.dropdown-form__wrapper {
  color: #434e52;
  font-weight: 500;
  font-size: 14px;
}
