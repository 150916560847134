@import "src/style/config/vars";

.confirm-deletion-small-inventory-value {
  &__heading {
    font-weight: $font-bold;
    font-size: 20px;
    color: $gray-500;
    margin-bottom: 30px;
    text-align: center;
  }

  &__description {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 15px;
    text-align: center;

    & .value {
      color: $gray-500;
      font-size: 11px;
    }
  }

  .btn {
    margin: 25px 0 10px;
    width: 100%;
    justify-content: center;
  }
}
