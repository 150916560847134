.squeeze-box {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-basis: auto;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &-arrow {
      transition: 0.3s;

      &.collapse {
        transform: rotate(180deg);
      }
    }
  }

  &__body {
    overflow: hidden;
    max-height: 0px;
    transition: 0.4s;
    box-sizing: border-box;
  }
}
