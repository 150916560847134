@import "src/style/config/vars";

.create-company {
  display: flex;
  flex-direction: column;
}
.create-company__title {
  font-weight: $font-bold;
  font-size: 18px;
  text-align: center;
  color: $gray-500;
  margin-bottom: 29px;
}
.create-company__description {
  font-weight: $font-bold;
  font-size: 12px;
  text-align: center;
  color: $red;
  opacity: 0.8;
  line-height: 14px;
  max-width: 343px;
  margin-bottom: 16px;
}
.create-company__controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 62px;
}
