// IMPORTS
@import "./config/reset";
@import "./helpers/mixins";
@import "./config/vars";
@import "./base/layout";
@import "./layout/buttons";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400;1,700&display=swap&family=Open+Sans:wght@700");

* {
  font-family: "Roboto", sans-serif;
}

// PRINT STYLING

@media print {
  .code {
    padding-top: 20px;
    text-align: center;
    &__title {
      display: block !important;
    }
  }
}
