@import "src/style/config/vars";
@import "src/style/helpers/mixins";

.inventory-config__content--parts {
  .inventory-config__item {
    position: relative;
    flex: 0 0 33.33%;
    max-width: 33.33%;
    margin-bottom: 20px;
    &::before {
      content: "";
      position: absolute;
      background-color: $gray-500;
      width: 3px;
      height: 20px;
      top: -30px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.inventory-config__content--parts-one-row,
.inventory-config__content--parts-left {
  .inventory-config__item {
    &::after {
      content: "";
      position: absolute;
      background-color: $gray-500;
      top: -30px;
      left: 0;
      width: calc(100% + 20px);
      height: 3px;
    }
  }
}

.inventory-config__content--parts-one-row {
  .inventory-config__item {
    &:first-child {
      &::after {
        width: calc(50% + 20px);
        left: calc(50%);
      }
    }
    &:last-child {
      &::after {
        width: 50%;
        left: 0;
      }
    }
  }
}

.inventory-config__content--parts-left {
  justify-content: flex-start;
  .inventory-config__item {
    &:first-child {
      &::after {
        width: calc(50% + 20px);
        left: calc(50%);
      }
    }
    &:nth-child(3) {
      &::after {
        width: 50%;
        left: 0;
      }
    }
    &:nth-child(n + 4) {
      margin-top: 20px;
      &::after {
        display: none;
      }
    }
  }
}
