@import "src/style/config/vars";

.items-table__checkbox {
  position: relative;
  top: 3px;
  width: 14px;
  height: 14px;
  border: none;
  border-radius: 3px;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    background-color: $gray-100;
    border-radius: 3px;
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    width: 16px;
    height: 16px;
    background-image: url("../../../../assets/checkbox.svg");
    background-size: contain;
    transition: opacity 0.1s ease;
  }
  &:checked {
    &::after {
      opacity: 1;
    }
  }
}
