@import "src/style/config/vars";

.item-type {
  padding: 4px 14px;
  border-radius: 30px;
  font-weight: 600;
  border: 2px solid rgba($orange, 0.7);
  white-space: nowrap;

  &.smallInventory {
    color: $green;
    border: 2px solid rgba($green, 0.7);
  }

  &.fixedAsset {
    color: $orange;
    border: 2px solid rgba($orange, 0.7);
  }

  &.immaterialAsset {
    color: $red;
    border: 2px solid rgba($red, 0.7);
  }
}
