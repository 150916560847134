@import "src/style/config/vars";
@import "src/style/helpers/mixins";

.create-category {
  display: flex;
  flex-direction: column;
}
.create-category__title {
  font-weight: $font-bold;
  font-size: 20px;
  text-align: center;
  color: $gray-500;
  margin-bottom: 52px;
}
.create-category__text {
  font-weight: $font-medium;
  font-size: 16px;
  color: $gray-400;
  opacity: 0.7;
  margin-bottom: 19px;
  padding-left: 14px;
}
.create-category__vector {
  width: 32px;
  height: 9px;
  padding-bottom: 3px;
}
.create-category__description {
  font-weight: $font-bold;
  font-size: 14px;
  text-align: center;
  color: $gray-400;
  opacity: 0.8;
  line-height: 16px;
  max-width: 343px;
  margin-bottom: 23px;
}
.create-category__controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 62px;
}
.create-category__icons {
  border: none;
  max-width: 335px;
  margin-bottom: 20px;
}
.create-category__row {
  display: flex;
  align-items: center;
}
.create-category__row:not(:last-child) {
  margin-bottom: 5px;
}
.create-category__category-icon {
  width: 30px;
  fill: $gray-400;
}
.create-category__icon-button {
  border: none;
  background: $white;
  width: 42px;
  padding: 7px 6px 5px;
  @include hover {
    box-shadow: 0px 3px 4px rgba($black, 0.2);
    border-radius: 5px;
  }
}
.create-category__icon-button:not(:last-child) {
  margin-right: 18px;
}
