@import "src/style/config/vars";
@import "src/style/helpers/mixins";

.user__navigation {
  margin-top: auto;
  margin-bottom: auto;

  @include media-breakpoint-up($md) {
    margin-bottom: 20px;
  }
}
.user__link-list {
  width: 100%;
}
.user__list-item {
  margin-bottom: 10px;
  &:last-of-type {
    margin-bottom: 0;
  }
}
.user__link {
  align-items: center;
  background-color: transparent;
  border-radius: 4px;
  border: 4px solid transparent;
  color: $gray-500;
  display: flex;
  font-weight: $font-medium;
  justify-content: center;
  padding: 9px 5px;
  transition: $transition;
  width: 100%;

  &.active {
    border-left: 4px solid $gray-500;
    border-radius: 0;
  }

  @include media-breakpoint-up($md) {
    justify-content: flex-start;
    padding: 9px 10px 9px 25px;
  }

  @include hover {
    background-color: rgba($gray-500, 0.05);
  }
}

.user__icon,
.user__link .icon {
  width: 25px;
  fill: $gray-500;

  @include media-breakpoint-up($md) {
    margin-right: 18px;
  }
}

.user__label {
  display: none;

  @include media-breakpoint-up($md) {
    display: inline-block;
  }
}
