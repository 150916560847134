@import "src/style/config/vars";
@import "src/style/helpers/mixins";

.items-table__table-parts {
  width: 100%;
  margin: 20px 0 30px;
  border-radius: 5px;
  .items-table__data {
    &:nth-of-type(9) {
      min-width: 71px;
    }
  }
}

.items-table__toggle {
  align-items: center;
  display: flex;
  position: absolute;
  transition: $transition;
  z-index: 11;
  top: 100%;
  left: 0;
  margin-left: 15px;
  transform: translateY(-200%);

  .icon {
    color: rgba($gray-500, 1);
    font-size: 25px;
    transition: $transition;
  }

  @include media-breakpoint-up($md) {
    position: relative;
    top: auto;
    left: auto;
    margin: 0;
    transform: none;
  }
}

.items-table__btn {
  background-color: unset;
  outline: 0;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  transition: 0.3s ease;

  @include hover {
    &:hover {
      opacity: 1;
      .icon {
        color: $green;
      }
    }
  }
}

.items-table__btn--active {
  opacity: 1;
  .icon {
    color: $green;
  }
}

.items-table__parts-amount {
  display: block;
  font-size: 14px;
  margin-left: 5px;
}

.items-table__row-part {
  border-radius: 0;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background-color $transition;
  background-color: $background;
  padding: 10px;
  position: relative;

  .census-indicator {
    position: absolute;
    top: 5px;
    right: 92%;
    left: 0;
    height: 15px;
    border-radius: 0 8px 8px 0;

    &.incomplete {
      background-color: #b99fea;
      &:after {
        content: "Nepopisano";
        font-size: 10px;
        top: 0;
        position: absolute;
        color: white;
      }
    }

    &.completed {
      background-color: #cde966;
      &:after {
        content: "Potvrđeno";
        font-size: 10px;
        top: 0;
        position: absolute;
        color: #005344;
      }
    }

    &.pending {
      background-color: #95110a;
      &:after {
        content: "Za proveru";
        font-size: 10px;
        top: 0;
        position: absolute;
        color: white;
      }
    }
  }

  &:hover {
    background-color: rgba($gray-500, 0.05);
  }

  @include media-breakpoint-up($md) {
    margin-bottom: 0;
    background-color: transparent;
    padding: 0;
  }
}

.items-table__row-parts {
  max-height: 0px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  transition: opacity 0.2s cubic-bezier(0, 0, 0.21, 1);
  z-index: 2;

  @include media-breakpoint-up($md) {
    background-color: $background;
  }
}
