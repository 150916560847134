@import "src/style/config/vars";

.input-switch__container--description {
  padding: 5px;
  position: relative;
  flex-flow: row-reverse;
  display: inline-flex;
  width: 100%;
  margin-left: 0%;
}

.preferences_title {
  font-weight: $font-bold;
  font-size: 20px;
  text-align: center;
  color: $gray-500;
  margin-bottom: 20px;
}

.columns_title {
  font-weight: $font-bold;
  font-size: 18px;
  text-align: center;
  color: $gray-500;
  margin-top: 4%;
  margin-bottom: 2%;
}

.preferences_foother_info {
  margin-left: 50%;
  margin-top: 7%;

  &__warning {
    font-weight: bold;
    margin-top: 1%;
    margin-left: 34%;
  }
}

.preferences_controls_group {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  margin-top: 8%;

  .btn {
    width: calc(50% - 12px);
    flex: 0 0 calc(50% - 12px);
    min-width: max-content;
    justify-content: center;
  }
}
