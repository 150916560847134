@import "src/style/config/vars";

.create-inventory-item {
  display: flex;
  flex-direction: column;
  min-height: 450px;
}
.create-inventory-item__title {
  font-weight: $font-bold;
  font-size: 18px;
  text-align: center;
  color: $gray-500;
  margin-bottom: 29px;
}
.create-inventory-item__description {
  font-weight: $font-bold;
  font-size: 12px;
  text-align: center;
  color: $red;
  opacity: 0.8;
  line-height: 14px;
  max-width: 343px;
  margin-bottom: 16px;
}
.create-inventory-item__controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 62px;
}

.create-inventory-item__search {
  display: block;

  p {
    margin-bottom: 15px;
  }

  &.right {
    position: absolute;
    top: 10px;
    right: 10px;
    padding-right: 5px;
    max-height: 150px;
    width: 250px;
    overflow-y: scroll;
    li {
      text-align: right;
      padding-left: 5px;
      &:not(:last-of-type) {
        margin-bottom: 5px;
        border-bottom: 1px solid $gray-300;
      }
    }
  }
}
