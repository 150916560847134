@import "src/domain/Settings/components/settings-content/settings-content";
@import "src/style/config/vars";
@import "src/style/helpers/mixins";

.small-inventory-value-settings {
  & .squeeze-box {
    box-shadow: 0px 0px 15px 0px rgba($color: #000000, $alpha: 0.05);
  }

  & .squeeze-box__header {
    font-weight: $font-bold;
    color: $gray-500;
    background: rgb(255, 255, 255);
    padding: 12px 16px;
  }

  &__previous-values-body {
    padding: 12px 10px;
    background-color: rgb(251, 251, 251);
    max-height: 176px;
    overflow-y: auto;
  }

  & .heading {
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.07);
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  &__no-previous-value {
    text-align: center;
    font-size: 12px;
    font-weight: $font-bold;
    padding: 5px;
    color: $gray-500;
  }

  &__previous-value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 8px;
    font-size: 12px;
    font-weight: 600;
    color: $gray-500;

    &:not(:last-child) {
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.08);
    }

    &-actions {
      display: flex;
    }

    &-delete {
      width: 12px;
    }
  }

  &__description {
    font-weight: $font-bold;
    font-size: 13px;
    color: $gray-500;
  }

  &__value {
    margin-left: 5px;
    font-weight: $font-bold;
    font-size: 14px;
  }

  &__warning {
    font-weight: $font-bold;
    font-size: 13px;
    color: $gray-500;
  }

  > div {
    margin-bottom: 15px;
  }

  &__delete {
    display: flex;
    margin-left: 18px;
    .btn {
      padding: 0;
      box-shadow: none;
      border: 0;
      transition: transform 0.3s ease-in;
      @include hover {
        transform: scale(1.2);
      }
    }
  }
}
