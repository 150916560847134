@import "src/style/config/vars";
@import "src/style/helpers/mixins";

#modal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $z-index-modal;
}
.modal__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: rgba($gray-500, 0.7);
  animation: fadeIn 0.2s 1 ease-in-out;
  padding: 0 10px;
  -webkit-transform-style: preserve-3d;
  -webkit-perspective: 600px;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modal__top-line {
  justify-content: flex-end;
  position: sticky;
  width: 100%;
  z-index: 1;

  .btn {
    background: none;
    border: 0;
    box-shadow: none;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: transform $transition;

    @include hover {
      transform: scale(1.2);
    }
  }

  .modal__close-icon {
    width: 17px;
  }
}

.modal__container {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  max-width: 499px;
  position: relative;
}

.modal__content {
  overflow-y: auto;
  padding: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: $gray-shadow;
  width: 100%;
  max-height: 850px;
  background-color: $white;
  border-radius: 5px;
  animation: scaleIn 0.2s 1 ease;
  position: relative;
  &.large {
    max-width: 850px;
  }

  @include media-breakpoint-up($md) {
    padding: 26px 40px 27px;
  }
}
@keyframes scaleIn {
  0% {
    filter: blur(5px);
    transform: translateY(5%) translateZ(-60px);
  }
  100% {
    filter: blur(0);
    transform: translateY(0) translateZ(0);
  }
}

.modal__close-btn {
  position: absolute;
  top: 20px;
  right: 20px;

  .btn {
    padding: 0;
    border: 0;
    box-shadow: none;
    transition: transform 0.3s ease-in;
    @include hover {
      transform: scale(1.2);
    }
  }
}
