@import "src/style/config/vars";
@import "src/style/helpers/mixins";

.parts {
  padding: 20px 10px;
  .sticker {
    flex: 0 0 85%;
    max-width: 85%;
    width: auto !important;
    height: auto !important;
  }
  .sticker__title {
    font-size: 16px;
  }
  .sticker__subtitle,
  .sticker__description,
  .sticker__footer {
    font-size: 14px;
    line-height: 16px;
  }
}

.parts__header {
  margin-bottom: 10px;
}

.parts__content {
  display: flex;
  gap: 20px;
  padding: 5px;
}

.parts__content--scroll {
  overflow-x: scroll;
}

.parts__title {
  font-size: 21px;
  line-height: 24px;
  text-align: center;
}

.parts__tooltip {
  @include media-breakpoint-up($lg) {
    display: none;
  }
  @include media-breakpoint-down($lg) {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
  }
}
