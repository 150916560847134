@import "src/style/config/vars";
@import "src/style/helpers/mixins";

.history-item {
  display: block;
  padding-right: 50px;
  position: relative;
  width: 100%;

  &:not(:last-child) {
    border-bottom: 1px solid rgba($gray-400, 0.2);
  }
}

.history-item__top {
  padding: 10px 0;
}

.history-item__chevron {
  border-radius: 100px;
  border: 2px solid transparent;
  box-sizing: border-box;
  display: block;
  height: 22px;
  position: relative;
  width: 22px;

  &::after {
    border-bottom: 2px solid;
    border-right: 2px solid;
    box-sizing: border-box;
    content: "";
    display: block;
    height: 10px;
    left: 4px;
    position: absolute;
    top: 2px;
    transform: rotate(45deg);
    width: 10px;
    transform-origin: center;
  }
}

.history-item__chevron--right::after {
  transform: rotate(-45deg);
}

.history-item__toggle {
  align-items: center;
  background: none;
  border: none;
  display: flex;
  height: 40px;
  justify-content: center;
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  transition: opacity 0.3s ease-out;

  &:hover {
    opacity: 0.5;
  }

  .history-item__chevron {
    transform: rotate(0deg);
    transition: transform 0.3s ease-in;
  }
}

.history-item__toggle--active .history-item__chevron {
  transform: rotate(180deg);
}

.history-item__extra-info {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 10px 0 20px;
}

.history-item__extra-item {
  display: flex;
  flex-direction: column;
  width: 100%;

  &:not(:last-child) {
    border-bottom: 1px solid rgba($gray-400, 0.2);
  }
}

.history-item__extra-item-label {
  display: block;
  padding: 10px 0 0;
  position: relative;
  text-align: left;
  width: 100%;
}

.history-item__extra-item-values {
  align-items: center;
  display: grid;
  gap: 20px;
  grid-auto-flow: column;
  grid-template-columns: 1fr 40px 1fr;
  overflow: hidden;
  padding: 10px 0;
  width: 100%;
}

.history-item__extra-item-before--with-label {
  text-align: left;
  font-weight: $font-medium;
}

.history-item__extra-item-separator {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.history-item__extra {
  max-height: 0;
  opacity: 0;
  transition:
    max-height 0.3s ease-in-out,
    opacity 0.2s ease-out;
}

.history-item__extra--open {
  max-height: 100vh;
  opacity: 1;
}
