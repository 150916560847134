@import "src/style/config/vars";
@import "src/style/helpers/mixins";

.container {
  margin: 0 auto;
  padding-bottom: 10px;
  text-align: center;
}

.row {
  position: relative;
  width: 100%;
  display: flex;
}

.row--column {
  flex-direction: column;
}

.info-row {
  display: flex;
  text-align: left;
}

.icon-column {
  flex: 0 0 45px;
  height: 45px;
  padding: 0;
  width: 45px;
}

.info-column {
  padding: 0 0 0 10px;
  flex: 1;
}

.info-column-name {
  flex: 1;
  inline-size: 100%;
  overflow-wrap: break-word;
  font-weight: bold;
  color: $gray-400;
}

.info-column-text {
  flex: 1;
  inline-size: 100%;
  overflow-wrap: break-word;
}

.info-column-date {
  flex: 1;
  color: rgba($gray-400, 0.6);
}

.user-avatar {
  display: inline-block;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}

.info-column-bold-text {
  color: $gray-400;
}

.closeIcon {
  border: 1px solid red;
  align-items: center;
  background: none;
  border: none;
  display: flex;
  height: 20px;
  justify-content: center;
  outline: none;
  position: absolute;
  right: 15px;
  top: 0;
  width: 20px;
  transition: opacity 0.3s ease-out;

  &:hover {
    opacity: 0.5;
  }
}
