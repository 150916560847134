.export-items-table-container {
  display: flex;
  flex: 1 1 auto;
  position: relative;
  width: 100%;
}

.history-wrap {
  border: 1px solid red;
}
