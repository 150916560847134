@import "src/style/config/vars";
@import "src/style/helpers/mixins";

.modal-confirmation {
  display: block;
}

.modal-confirmation__title {
  color: $gray-500;
  font-size: 20px;
  font-weight: $font-bold;
  margin-bottom: 25px;
  text-align: center;
}

.modal-confirmation__text {
  font-size: 16px;
  font-weight: $font-medium;
  margin-bottom: 25px;
}

.modal-confirmation__controls {
  align-items: center;
  display: flex;
  justify-content: space-around;
}

.delete-item-data {
  li {
    list-style: disc;
    margin-left: 20px;
  }
}
