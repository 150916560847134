@import "src/style/config/vars";
@import "src/style/helpers/mixins";

.dropdown__button {
  width: 100%;
  padding: 10px 25px 10px 10px;
  color: $gray-400;
  background-color: $white;
  box-shadow: $primary-shadow;
  text-align: left;
  border: none;
  font-weight: $font-medium;
  position: relative;

  @include media-breakpoint-up($md) {
    padding: 13px 20px;
  }
}

.dropdown__icon {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  font-size: 12px;
  color: $gray-300;

  @include media-breakpoint-up($md) {
    right: 15px;
  }
}
