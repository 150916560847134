@import "../../../../../style/config/vars";
@import "../../../../../style/helpers/mixins";

.dropdown__choice {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 7px 0 7px 10px;
  color: $gray-400;
  border: none;
  border-radius: 5px;
  background-color: $white;
  transition: background-color $transition;
  text-align: left;
  font-weight: $font-medium;
  @include hover {
    background-color: rgba($gray-300, 0.4);
  }
  @include media-breakpoint-down($xl) {
    padding: 7px 10px;
  }
}

.dropdown__checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13px;
  min-width: 13px;
  height: 13px;
  margin-right: 10px;
  border-radius: 2px;
  background-color: $gray-200;
}

.dropdown__check-icon {
  color: $gray-400;
  font-size: 10px;
}
