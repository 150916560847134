@import "src/style/config/vars";
@import "src/style/helpers/mixins";

.configuration-changes {
  display: flex;
  flex-direction: column;
  min-height: 450px;
}

.configuration-changes__title {
  font-weight: $font-bold;
  font-size: 18px;
  text-align: center;
  color: $gray-500;
  margin-bottom: 29px;
}

.configuration-changes__text_section {
  padding-bottom: 20px;
}

.configuration-changes__controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 62px;
}

.configuration-changes__list {
  padding-left: 30px;
}

.configuration-changes__list-item {
  list-style-type: disc;
}
