@import "src/style/config/vars";
@import "src/style/helpers/mixins";

.app {
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;

  @include media-breakpoint-up($sm) {
    grid-template-columns: $sidebar-mobile 1fr;
  }

  @include media-breakpoint-up($md) {
    grid-template-columns: $sidebar-desktop 1fr;
  }
}

.app__sidebar {
  display: flex;
  flex-direction: column;
  max-width: $sidebar-mobile;
  transition:
    max-width $transition,
    transform $transition;
  transform: translateX(-100%);
  width: 100%;
  will-change: transform;
  z-index: 1;

  @include media-breakpoint-up($sm) {
    transform: translateX(0);
  }

  @include media-breakpoint-up($md) {
    max-width: $sidebar-desktop;
  }

  @include hover {
    .sidebar {
      width: $sidebar-desktop;
    }

    .user__panel-profile {
      border: 1px solid rgba($gray-500, 0.15);
    }

    .user__icon,
    .user__link .icon {
      margin-right: 18px;
    }

    .user__link {
      justify-content: flex-start;
      padding: 9px 10px 9px 25px;
    }

    .user__panel-logo-small {
      display: none;
    }

    .user__panel-logo,
    .logout-button__title,
    .user__label,
    .user__panel-content {
      display: inline-block;
    }
  }
}

.app__content {
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 25px 15px 0 15px;
  position: relative;
  transform: translateX(0);
  transition: transform $transition;
  width: 100%;
  will-change: transform;
  height: 100vh;
  max-height: 100vh;

  @include media-breakpoint-up($sm) {
    padding-left: 25px;
  }
}

.app--nav-open {
  .app__content {
    transform: translateX($sidebar-desktop);
  }
  .app__sidebar {
    transform: translateX(0);

    .sidebar {
      width: $sidebar-desktop;
    }

    .user__panel-profile {
      border: 1px solid rgba($gray-500, 0.15);
    }

    .user__icon,
    .user__link .icon {
      margin-right: 18px;
    }

    .user__link {
      justify-content: flex-start;
      padding: 9px 10px 9px 25px;
    }

    .user__panel-logo-small {
      display: none;
    }

    .user__panel-logo,
    .logout-button__title,
    .user__label,
    .user__panel-content {
      display: inline-block;
    }
  }
}

.app__content--scan {
  padding: 0;
  height: 100vh;
}
