@import "src/style/config/vars";

.pagination {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 100vw;
  width: 100%;
}

.pagination__controls {
  border-top: 1px solid $gray-300;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: calc(100% - 10px);
}

.pagination__item {
  align-items: center;
  background-color: transparent;
  border-top: 2px solid transparent;
  color: $gray-400;
  display: flex;
  font-size: 14px;
  justify-content: center;
  min-width: 22px;
  padding: 10px;
}

.pagination__number--active {
  color: $primary;
  border-top-color: $primary;
}

.pagination__item--previous {
  margin-right: auto;
  padding-left: 0;

  svg {
    margin-right: 13px;
  }
}

.pagination__item--next {
  margin-left: auto;
  padding-right: 0;

  svg {
    margin-left: 13px;
  }
}

.pagination__item--disabled .pagination__button {
  pointer-events: none;
  color: $gray-300;
  svg {
    fill: $gray-300;
  }
}

.pagination__item-link {
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.pagination__button {
  align-items: center;
  background-color: transparent;
  border: none;
  color: $gray-400;
  cursor: pointer;
  display: flex;
  font-size: 14px;

  &:disabled {
    color: $gray-300;
    svg {
      fill: $gray-300;
    }
  }
  svg {
    width: 5px;
    fill: $gray-400;
    margin-top: -2px;
  }
}

.pagination__page-count {
  color: $gray-400;
  font-size: 12px;
  max-width: 250px;
}

.pagination__page-count-item {
  background-color: transparent;
  border: none;
  color: $gray-400;
  font-size: 14px;
  margin: 5px 10px;
}

.pagination__page-count-item--active {
  color: $primary;
  pointer-events: none;
}
