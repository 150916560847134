@import "src/style/config/vars";
@import "src/style/helpers/mixins";

.login-button__icon-wrapper {
  padding: 5px;
  background: $white;
  border-radius: 3px;
}
.login-button__icon {
  padding: 16px;
  background-size: contain;
}
.login-button__text {
  margin-left: 28px;
  margin-right: 18px;
  font-size: 16px;
  font-weight: $font-bold;
  color: $white;
  @include media-breakpoint-down($xl) {
    font-size: 14px;
    margin-left: 25px;
    margin-right: 24px;
  }
}
