@import "src/style/config/vars";

.text-input__label {
  font-size: 14px;
  font-weight: 500;
  color: rgba($gray-500, 0.9);
  .text-input__wrap--checkbox & {
    position: relative;
    bottom: 9px;
  }
}

.text-input {
  width: 100%;
  font-weight: $font-medium;
  color: $gray-500;
  padding-left: 14px;
  padding-bottom: 13px;
  border: none;
  border-bottom: 1px solid rgba($gray-400, 0.2);
  accent-color: $primary;
  &::placeholder {
    color: rgba($gray-500, 0.35);
  }
}

.text-input__wrap {
  position: relative;
  margin-bottom: 32px;
}

.text-input__hint {
  display: block;
}

.text-input__error {
  border-color: $red !important;
}

.text-input__error-mesage {
  color: $red;
  display: block;
  text-align: center;
  margin: -15px 0 15px;
  text-align: left;
}

.text-input__required {
  color: $red;
  margin-left: 2px;
}

.text-input[type="checkbox"] {
  min-width: 50px;
  min-height: 30px;
  width: auto;
  margin-left: 15px;
}
