@import "src/style/config/vars";

.sticker {
  box-shadow: 3px 3px 8px 0px rgba($black, 0.5);
  border-radius: 8px;
  margin-bottom: 10px;
  margin-right: 10px;

  font-family: $open-sans;
  font-size: 7.5pt;
  font-weight: 500;
  width: 1.97in !important;
  height: 0.979in !important;
  background-color: $white;
}

.sticker__content {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding: 8px 4px;

  &.reversed {
    flex-direction: row-reverse;

    .sticker__text {
      padding-right: 4px;
    }
  }
}

.sticker__qr-content {
  flex: 0 0 40%;
  display: flex;
  align-items: center;

  .code__qrcode {
    border-radius: 3px;
    width: 100%;
    height: 100%;
  }
}

.sticker__text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 0 0 60%;
  max-width: 60%;
  padding-left: 4px;
}

.sticker__title {
  font-size: 8pt;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 0.03em;
}

.sticker__subtitle {
  display: -webkit-box;
  max-width: 400px;
  line-height: 7.5pt;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sticker__description {
  margin-top: 4px;
  font-size: 7pt;
  letter-spacing: 0;
  font-style: italic;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
}

.sticker__footer {
  text-align: right;
  line-height: 7.5pt;
}

@media print {
  .sticker {
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 0;
    margin-right: 0;
    // printer configuration 4px bleed top;
    padding-top: 4px;
  }
}
