@import "src/style/config/vars";

.export-description {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: $gray-400;
  opacity: 0.9;
  padding: 20px 20px 20px;
}
