@import "style/config/vars";
@import "style/helpers/mixins";

.logout-button__title {
  display: none;

  @include media-breakpoint-up($md) {
    display: inline-block;
  }
}
